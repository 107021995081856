//@import "~bootstrap/dist/css/bootstrap.min.css";

h1 {
	font-size: 66px;
	color: #ffa7c4;
	margin-bottom: 0px;
	font-weight: 500;
	line-height: 1.1;
}

body {
	background-color: #282c35;
}

.heading {
	margin-top: 40px;
	margin-bottom: 30px;
	text-align: center;
	padding: 0px 10px;
}

h2 {
	text-align: center;
	font-size: 22px;
	color: rgb(255, 167, 196);
	letter-spacing: 0.2px;
	margin-top: 50px;
	font-weight: 500;
	line-height: 1.1;
}

.color-default {
	color: #e6e6e6;
	font-size: 1rem;
}
.color-pink {
	color: rgb(255, 167, 196);
	font-size: 1rem;
}

.color-light {
	letter-spacing: 0.2px;
	font-size: 13px;
	padding-right: 5px;
}

.text-info {
	font-size: 1rem;
	display: block;
	margin: auto;
	text-align: center;
	padding: 10px 20px;
	max-width: 380px;

	margin-bottom: 20px;
}

.footer {
	color: rgb(255, 167, 196);
	font-size: 15px;
	display: block;
	margin: auto;
	text-align: center;
	padding: 10px 20px;
	max-width: 500px;
	margin-top: 60px;
	margin-bottom: 10px;
	& a {
		color: #e6e6e6;
		font-size: 12px;
		text-decoration: none;
		&:hover {
			color: #f9004d;
		}
	}
}

.pace-form {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 0px 20px;

	form {
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: #fff;
		max-width: 380px;
		min-width: 280px;
		margin: auto;
		border-radius: 30px;
		padding: 25px 20px 25px 10px;
	}

	.label-light {
		display: block;
		padding-left: 6px;
		margin-bottom: 4px;
		margin-top: 2px;
		letter-spacing: 0.2px;
	}

	.label-mix {
		display: block;
		padding-left: 13px;
		margin-bottom: 4px;
		margin-top: 2px;
		letter-spacing: 0.2px;
	}

	.label-bold {
		display: block;
		padding-top: 5px;
		padding-left: 13px;
		margin-bottom: -8px;
		font-weight: bold;
		letter-spacing: 0.2px;
	}

	input {
		width: calc(100% - 20px);
		padding-left: 15px;
		overflow: hidden;
		text-overflow: ellipsis;
		border: 2px solid;
		border-radius: 5px;
		height: 50px;
		line-height: 46px;
		margin-bottom: 8px;
		outline: none;
		color: #1f1f25;
		font-size: 15px;
		letter-spacing: 0.2px;
		transition: all 0.3s ease;
		&:focus {
			border: 2px solid #f9004d;
		}
	}

	.calculated-field {
		background: #e1dee6;
		border-color: #ec669380;
	}
	.user-input {
		background: transparent;
		border-color: rgba(255, 167, 196, 0.5);
	}
}

.form-header {
	display: flex;
	justify-content: flex-start;
	flex-wrap: nowrap;
	margin-top: -5px;
	margin-bottom: 8px;
}

.align-left {
	margin-left: auto;
	padding: 5px;
}

.row {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	padding-top: 2px;
}

.column {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	flex: 1;
	padding-left: 8px;
}

/*===================
  Button Area 
  ====================*/

button {
	&.reset {
		margin-left: 5px;
		padding: 5px;
		color: #282c35;
		border-radius: 30px;
		font-size: 13px;
		background: #f2f1f4;
		border-color: transparent;
		letter-spacing: 0.3px;
		transition: 0.3s;

		&:hover {
			color: #f9004d;
			transform: translateY(-5px);
		}

		&:focus {
			outline: none;
		}
	}
}

/*===================
  switch Area 
  ====================*/

.rc-switch {
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	width: 47px;
	height: 22px;
	line-height: 20px;
	//margin-bottom: 2px;
	vertical-align: middle;
	border-radius: 20px 20px;
	border: 1px solid #f9004d;
	background-color: #f9004d;
	cursor: pointer;
	transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.rc-switch-inner {
	color: #fff;
	// font-style: italic;
	font-weight: 900px;
	font-size: 11px;
	position: absolute;
	left: 23px;
	right: 24px;
	bottom: 1px;
}
.rc-switch:after {
	color: #fff;
	position: absolute;
	width: 18px;
	height: 18px;
	left: 2px;
	top: 1px;
	border-radius: 50% 50%;
	background-color: #fff;
	content: "";
	cursor: pointer;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
	-webkit-transform: scale(1);
	transform: scale(1);
	transition: left 0.3s cubic-bezier(0.36, 0, 0.25, 1);
	-webkit-animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
	animation-timing-function: cubic-bezier(0.36, 0, 0.25, 1);
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-name: rcSwitchOff;
	animation-name: rcSwitchOff;
}
.rc-switch:hover:after {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	-webkit-animation-name: rcSwitchOn;
	animation-name: rcSwitchOn;
}
.rc-switch:focus {
	box-shadow: 0 0 0 2px #d5f1fd;
	outline: none;
}
.rc-switch-checked {
	border: 1px solid #ffa7c480;
	background-color: #282c35;
}

.rc-switch-checked .rc-switch-inner {
	left: 7px;
}
.rc-switch-checked:after {
	left: 25px;
}

@-webkit-keyframes rcSwitchOn {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	50% {
		-webkit-transform: scale(1.25);
		transform: scale(1.25);
	}
	100% {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}
}
@keyframes rcSwitchOn {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	50% {
		-webkit-transform: scale(1.25);
		transform: scale(1.25);
	}
	100% {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}
}
@-webkit-keyframes rcSwitchOff {
	0% {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@keyframes rcSwitchOff {
	0% {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
